<template>
  <div>
    <strong class="asterisk-div" style="font-size: 18px">
      参数配置
    </strong>
    <div class="mt-4">
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="5">
          <v-text-field
              autocomplete="off"
              hide-details
              dense
              label="反馈内容"
              v-model="item.param"
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
              autocomplete="off"
              hide-details
              dense
              label="回复内容"
              v-model="item.formula"
          />
        </v-col>
        <v-col cols="1">
          <v-icon @click="remove(index)" size="28" style="cursor: pointer" class="iconfont"
                  :style="{color: 'var(--v-redness-base)'}">
            iconfont icon-shanchu-fill
          </v-icon>
        </v-col>
        <v-col cols="1" v-if="index === items.length - 1">
          <v-btn color="primary" depressed style="width: 28px; min-width: 28px; height: 28px !important;" @click="add">
            <v-icon style="cursor: pointer" class="iconfont" size="16">
              iconfont icon-zengjia
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "mini-program-content",
  data() {
    return {
      items: [
        {formula: '', param: ''}
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    add() {
      this.items.push({formula: '', param: ''})
    },
    remove(index) {
      this.items.splice(index, 1)
    }
  },
  watch: {
    items: {
      handler(v) {
        this.$emit('change', JSON.stringify(v))
      },
      immediate: true,
      deep: true
    },
    value: {
      handler(v) {
        if (v) {
          this.items = JSON.parse(v)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
