<template>
  <common-table
      :itemScope="['status']"
      :headers="headers"
      :listData="listData"
      @changePage="changePage"
      :loading="loading"
      :btnAction="true"
      @search="search"
      @remove="remove"
      @save="save"
      @edit="edit"
      ref="ct"
      :leftConfig="leftConfig"
      :rightConfig="rightConfig"
      :listDeploy="listDeploy"
      pageTitle="小程序通知"
  >
    <template v-slot:status="{ item }">
      <w-switches
          inset
          label=""
          color="var(--v-primary-base)"
          @change="updateStatus(item)"
          v-model="item.status" hide-details dense/>
    </template>
  </common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/notice/mini-program.js';
import miniProgramContent from "./mini-program-content";

export default {
  mixins: [commonCurd],
  data() {
    return {
      api,
      leftConfig: [
        {type: 'input', label: '输入模版名称', property: 'description'}
      ],
      rightConfig: [
        {label: '新建', icon: 'zengjia', event: 'add'}
      ],
      headers: [
        {text: '代码', value: 'code'},
        {text: '描述', value: 'description'},
        {text: '触发方式', value: 'trigger'},
        {text: '页面跳转url', value: 'pageurl'},
        {text: '订阅模板号', value: 'outid'},
        {text: '状态', value: 'status'},
        {text: '操作', value: 'actions', width: 120}
      ],
      listDeploy: {
        row: [
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'code',
            hasEditDisabledProperty: true,
            connector: {
              props: {
                required: true,
                label: "通知代码"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'description',
            connector: {
              props: {
                required: true,
                label: "通知描述"
              }
            }
          },
          {
            cols: 6,
            subassembly: "WAutocomplete",
            property: 'trigger',
            connector: {
              props: {
                required: true,
                label: "触发方式"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'pageurl',
            connector: {
              props: {
                required: false,
                label: "页面跳转url"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'outid',
            connector: {
              props: {
                required: false,
                label: "订阅模板号"
              }
            }
          },
          {
            cols: 6,
            subassembly: "WAutocomplete",
            property: 'target',
            connector: {
              props: {
                required: true,
                label: "消息发送目标",
                items: [{code: 1, desc: '正式版'}, {code: 2, desc: '体验版'}, {code: 3, desc: '开发版'}]
              }
            }
          },
          {
            cols: 6,
            subassembly: "WSwitches",
            disabledDeleteDefault: true,
            property: 'status',
            connector: {
              props: {
                label: '状态',
                inset: true,
                dense: true
              }
            },
            default: true
          },
          {
            subassembly: miniProgramContent,
            cols: 12,
            property: 'content'
          }
        ]
      }
    }
  },
  methods: {
    updateStatus(item) {
      this.axios.post(this.api.updateStatus, {sqlid: item.sqlid, status: !item.status}).then((res) => {
        if (res.code === this.staticVal.Code.Success) {
          this.snackbar.success('更新成功');
        }
      })
    }
  },
  mounted() {
    this.axios.post(this.select_data, {keyname: 'msgtrigger'}).then((res) => {
      if (res.code === this.staticVal.Code.Success) {
        this.listDeploy.row[2].connector.props.items = res.data[0].values
      }
    })
  }
}
</script>

<style scoped lang="scss">

</style>
